export default {
    monitoring: '/monitoring',
    // tableMonitoring: '/table-monitoring/',
    map: '/map',
    companies: '/companies',
    offices: '/offices',
    reports: '/reports',
    users: '/users',
    settings: '/settings',
    archive: '/archive',
    groups: '/groups',
    mailing: '/mailing',
    assignment: '/assignment',
    logs: '/logs',
    widgets: '/widgets',
    prerecord: '/prerecord',
    backups: '/backup',
};
