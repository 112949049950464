import React from 'react';
import { UserOutlined, AreaChartOutlined, BankOutlined, HomeOutlined, CopyOutlined, EnvironmentOutlined, SettingOutlined, DatabaseOutlined, MailOutlined, UnorderedListOutlined, BlockOutlined, GroupOutlined, FormOutlined, ConsoleSqlOutlined, } from '@ant-design/icons';
import paths from '@/utils/paths';
var iconSize = '20px';
export default [
    {
        key: paths.monitoring,
        icon: <AreaChartOutlined style={{ fontSize: iconSize }}/>,
        label: <p className="Мониторинг">Мониторинг</p>,
    },
    // {
    //   key: paths.tableMonitoring,
    //   icon: <TableOutlined />,
    //   label: 'Табличный мониторинг',
    // },
    {
        key: paths.map,
        icon: <EnvironmentOutlined style={{ fontSize: iconSize }}/>,
        label: <p className="menu__title">Карта</p>,
    },
    {
        key: paths.reports,
        icon: <CopyOutlined style={{ fontSize: iconSize }}/>,
        label: <p className="menu__title">Отчёты</p>,
    },
    {
        key: paths.archive,
        icon: <DatabaseOutlined style={{ fontSize: iconSize }}/>,
        label: <p className="menu__title">Архив</p>,
    },
    {
        key: paths.widgets,
        icon: <BlockOutlined style={{ fontSize: iconSize }}/>,
        label: <p className="menu__title">Виджеты</p>,
    },
    {
        key: paths.prerecord,
        icon: <FormOutlined style={{ fontSize: iconSize }}/>,
        label: <p className="menu__title">Предварительная запись</p>,
    },
    {
        key: 'settings',
        icon: <SettingOutlined style={{ fontSize: iconSize }}/>,
        label: <p className="menu__title">Настройки</p>,
        private: 'true',
        children: [
            {
                key: paths.companies,
                icon: <BankOutlined style={{ fontSize: iconSize }}/>,
                label: <p className="menu__title">Компании</p>,
            },
            {
                key: paths.offices,
                icon: <HomeOutlined style={{ fontSize: iconSize }}/>,
                label: <p className="menu__title">Офисы</p>,
            },
            {
                key: paths.groups,
                icon: <GroupOutlined style={{ fontSize: iconSize }}/>,
                label: <p className="menu__title">Группировки</p>,
            },
            {
                key: paths.users,
                icon: <UserOutlined style={{ fontSize: iconSize }}/>,
                label: <p className="menu__title">Пользователи</p>,
            },
            {
                key: paths.mailing,
                icon: <MailOutlined style={{ fontSize: iconSize }}/>,
                label: <p className="menu__title">Адреса для рассылки</p>,
            },
            {
                key: paths.settings,
                icon: <SettingOutlined style={{ fontSize: iconSize }}/>,
                label: <p className="menu__title">Параметры</p>,
            },
            {
                key: paths.backups,
                icon: <ConsoleSqlOutlined style={{ fontSize: iconSize }}/>,
                label: <p className="menu__title">Резервные копии</p>,
            },
            {
                key: paths.logs,
                icon: <UnorderedListOutlined style={{ fontSize: iconSize }}/>,
                label: <p className="menu__title">Логи</p>,
            },
        ],
    },
    // {
    //   key: paths.assignment,
    //   icon: <FileExclamationOutlined />,
    //   label: 'Распределение талонов',
    // },
];
