var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import getColumnSearchProps from '@/utils/tableSearch';
var getColumns = function () { return [
    __assign({ key: Math.random(), width: 1 }, getColumnSearchProps('name', 'по названию офиса')),
    {
        dataIndex: 'name',
        title: 'Офис',
    },
]; };
var getExpandColumns = function (download) { return [
    {
        dataIndex: 'name',
        title: 'Название',
    },
    {
        dataIndex: 'createdAt',
        title: 'Дата создания',
        render: function (value) { return (dayjs(value).format('DD.MM.YYYY')); },
    },
    {
        key: 'actions',
        align: 'center',
        width: 200,
        render: function (_val, row) { return (<DownloadOutlined onClick={function () { return download(row); }} style={{ fontSize: '24px' }}/>); },
    },
]; };
export { getColumns, getExpandColumns };
