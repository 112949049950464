import React from 'react';
import { Table } from 'antd';
import { useQuery } from 'react-query';
import { getColumns, getExpandColumns } from '@/pages/Backups/tableColumns';
import { apiAdmin } from '@/utils/constants';
import offices from '@/utils/rest/offices';
function Backups() {
    var _a = useQuery('backups', offices.getBackups), data = _a.data, isLoading = _a.isLoading;
    var download = function (row) {
        var link = document.createElement('a');
        link.href = "".concat(apiAdmin, "/office/backups?src=").concat(row.path);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (<>
      <h2 className="page__header">Резервные копии БД офисов</h2>
      <div className="page__content">
        <Table rowKey="id" dataSource={data} loading={isLoading} columns={getColumns()} expandable={{
            expandRowByClick: true,
            expandedRowRender: function (row) { return (<Table pagination={false} rowKey="name" dataSource={row.backups} columns={getExpandColumns(download)}/>); },
        }}/>
      </div>
    </>);
}
export default Backups;
