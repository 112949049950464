import SecToTime from '@/utils/rest/secToTime';
export default (function (toHours) { return [
    {
        title: 'Наименование ТУ',
        dataIndex: 'name',
    },
    {
        title: 'Адрес',
        dataIndex: 'situation',
    },
    {
        title: 'Количество закрытых талонов',
        dataIndex: 'statistic.ticket_count',
        align: 'center',
    },
    {
        title: 'Количество сеансов обслуживания',
        dataIndex: 'statistic.session_count',
        align: 'center',
    },
    {
        title: 'Общее время ожидания',
        dataIndex: 'statistic.total_wait',
        align: 'center',
        render: function (value) { return SecToTime(value, toHours); },
    },
    {
        title: 'Ср. время ожидания',
        dataIndex: 'statistic.avg_wait',
        align: 'center',
        render: function (value) { return SecToTime(value, toHours); },
    },
    {
        title: 'Количество ожидающих менее 15 минут',
        dataIndex: 'statistic.less_15',
        align: 'center',
    },
    {
        title: 'Процент ожидающих менее 15 минут',
        dataIndex: 'statistic.percentage_less_15',
        align: 'center',
        render: function (value) { return (value !== null ? "".concat(String(value), " %") : 0); },
    },
    {
        title: 'Количество ожидающих более 15 минут',
        dataIndex: 'statistic.more_15',
        align: 'center',
    },
    {
        title: 'Процент ожидающих более 15 минут',
        dataIndex: 'statistic.percentage_more_15',
        align: 'center',
        render: function (value) { return (value !== null ? "".concat(String(value), " %") : 0); },
    },
    {
        title: 'Кол-во обслуженных талонов',
        dataIndex: 'served_count',
        align: 'center',
    },
    {
        title: 'Общее время обслуживания',
        dataIndex: 'statistic.total_proc',
        align: 'center',
        render: function (value) { return SecToTime(value, toHours); },
    },
    {
        title: 'Среднее время обслуживания',
        dataIndex: 'statistic.avg_proc',
        align: 'center',
        render: function (value) { return SecToTime(value, toHours); },
    },
]; });
