var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined, LogoutOutlined, BorderOutlined, } from '@ant-design/icons';
import { Layout, Menu, Popover } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import Cookies from 'js-cookie';
import { useMutation, useQuery } from 'react-query';
import { Link, Outlet, useLocation, useNavigate, } from 'react-router-dom';
import { AppStore, UserStore } from '@/store';
import { isReader } from '@/utils/checkRole';
import { colors } from '@/utils/constants';
import navItems from '@/utils/navItems';
import paths from '@/utils/paths';
import settings from '@/utils/rest/settings';
import user from '@/utils/rest/user';
import widgets from '@/utils/rest/widgets';
import pJson from '../package.json';
import { ReactComponent as Dot } from './assets/icons/officeMark.svg';
import { ReactComponent as Logo } from './assets/images/logo.svg';
dayjs.extend(utcPlugin);
function App() {
    var navigate = useNavigate();
    var location = useLocation();
    var _a = UserStore.getRawState(), userName = _a.fullname, userRole = _a.role;
    var _b = useState(false), collapsed = _b[0], setCollapsed = _b[1];
    var _c = useState(navItems), menuItems = _c[0], setMenuItems = _c[1];
    useQuery('settings', settings.getAll, {
        onSuccess: function (params) {
            var preorderParam = params.find(function (el) { return el.param === 'showPreorder'; });
            if (!Number(preorderParam === null || preorderParam === void 0 ? void 0 : preorderParam.value)) {
                var idx = navItems.findIndex(function (el) { return el.key === paths.prerecord; });
                if (idx !== -1) {
                    navItems.splice(idx, 1);
                    setMenuItems(navItems);
                }
            }
            AppStore.update(function (state) { state.params = params; });
        },
    });
    useQuery('checkAuth', user.checkAuth, {
        onSuccess: function (userData) {
            if (userData.role === 'checklister') {
                window.location.replace("https://checklist.a-7.tech?token=".concat(userData.token));
                Cookies.remove('connect.sid');
            }
            else {
                Cookies.set('token', userData.token, { sameSite: 'none', secure: true });
                UserStore.update(function (s) {
                    s.fullname = userData.fullname;
                    s.role = userData.role;
                });
            }
        },
        onError: function () {
            navigate('/login');
        },
        retry: false,
    });
    var _d = useQuery('siderWidgets', widgets.getForSider, {
        onSuccess: function (widgetData) {
            var newItems = __spreadArray([], navItems, true);
            var idx = newItems.length - 1;
            widgetData.forEach(function (widget) {
                newItems.splice(idx, 0, {
                    key: "/widget/".concat(widget.id),
                    icon: <BorderOutlined style={{ fontSize: '20px' }}/>,
                    label: <p className="menu__title">{widget.name}</p>,
                });
            });
            setMenuItems(newItems);
            AppStore.update(function (state) { state.updateSider = refetch; });
        },
    }), isFetching = _d.isFetching, refetch = _d.refetch;
    var _e = useQuery('indicators', widgets.getIndicators, {
        onSuccess: function () {
            AppStore.update(function (state) { state.updateIndicators = refetchIndicators; });
        },
        refetchInterval: 5000,
    }), indicatorsData = _e.data, refetchIndicators = _e.refetch, isIndicatorsFetching = _e.isFetching;
    var logout = useMutation({
        mutationFn: function () { return user.logOut(); },
        onSuccess: function (data) {
            if (data.success) {
                navigate('/login');
            }
        },
    });
    var handleSelect = function (option) {
        navigate(option.key);
    };
    var toggleSider = function () {
        setCollapsed(function (prevState) { return !prevState; });
    };
    var logOut = function () {
        logout.mutate();
    };
    return (<Layout hasSider style={{ height: '100vh' }}>
      <Sider trigger={null} collapsible collapsed={collapsed} className="sider" theme="light">
        <p className="version">
          v.
          {' '}
          {pJson.version}
        </p>
        <Link className="logo" to={paths.monitoring}>
          <Logo />
        </Link>
        {!isFetching && (<Menu onClick={handleSelect} theme="light" mode="inline" items={isReader(userRole) ? menuItems.filter(function (el) { return !el.private; }) : menuItems} selectedKeys={[location.pathname]}/>)}
      </Sider>
      <Content>
        <Header className="header">
          {collapsed ? (<MenuUnfoldOutlined onClick={toggleSider} className="header__icon"/>) : (<MenuFoldOutlined onClick={toggleSider} className="header__icon"/>)}
          <div className="header__user">
            {!isIndicatorsFetching && (indicatorsData === null || indicatorsData === void 0 ? void 0 : indicatorsData.map(function (indicator) {
            var metaArr = Object.values(JSON.parse(indicator.meta));
            return !indicator.online
                ? (<Popover content="Сервис недоступен">
                    <Dot />
                  </Popover>)
                : (<Popover key={indicator.id} content={(<div>
                        <p>{indicator.description}</p>
                        {metaArr.filter(function (el) { return !el.isAlive; }).map(function (link) { return (<p className="link__text">
                            {link.url}
                            :
                            {' '}
                            <span>неактивна</span>
                          </p>); })}
                      </div>)}>
                    {metaArr.some(function (el) { return !el.isAlive; })
                        ? <Dot style={{ fill: colors.error }}/>
                        : <Dot style={{ fill: colors.normal }}/>}
                  </Popover>);
        }))}
            <p>{userName}</p>
            <LogoutOutlined onClick={logOut} style={{ fontSize: '24px' }}/>
          </div>
        </Header>
        <div style={{ padding: '0 10px 0', overflow: 'auto', height: '92vh' }}>
          <Outlet />
        </div>
      </Content>
    </Layout>);
}
export default App;
